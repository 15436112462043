import Vue from 'vue';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import VCalendar from 'v-calendar';
import VueRouter from 'vue-router';

import App from './App.vue';
import routes from './router';
import '@/scss/style.scss';

axios.interceptors.request.use(
	(request) => {
		const access_token = localStorage.getItem('access_token');

		if (access_token) {
			request.headers.Authorization = `Bearer ${access_token}`;
		}

		return request;
	},

	(error) => {
		return Promise.reject(error);
	}
);

axios.defaults.baseURL = `${process.env.VUE_APP_TERMINAL_API_URL}`;

Vue.config.productionTip = false;

Vue.use(VCalendar);
Vue.use(VueRouter);
Vue.use(Vuelidate);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	const token = localStorage.getItem('access_token');

	if (to.path === '/client' && to.query.token) {
		// TODO: check client token, return next() if token is valid
		return next();
	}

	if (to.path === '/bank' && to.query.token) {
		// TODO: check client token, return next() if token is valid
		return next();
	}

	if (to.path === '/forgot-password') {
		return next();
	}

	if (to.path === '/reset-password') {
		return next();
	}

	if (token && to.path === '/login') {
		return router.push('/');
	}

	if (!token && to.path !== '/login') {
		return router.push('/login');
	}

	next();
});

new Vue({
	router,
	render: (h) => h(App),
}).$mount('#app');

/**
 * Format a date
 */
Date.prototype.format = function (format = '%d.%m.%y') {
	const year = this.getFullYear();
	const month = this.getMonth();
	const day = this.getDate();
	const hours = this.getHours();
	const minutes = this.getMinutes();
	const seconds = this.getSeconds();

	const months = ['januar', 'februar', 'marts', 'apríl', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];

	return format
		.replace(/%y2/g, year.toString().substring(2, 2))
		.replace(/%y/g, String(year))

		.replace(/%mn3/g, months[month].substring(0, 3))
		.replace(/%mn/g, months[month])
		.replace(/%m/g, String(month + 1).padStart(2, '0'))
		.replace(/%-m/g, String(month + 1))

		.replace(/%d/g, String(day).padStart(2, '0'))
		.replace(/%-d/g, String(day))

		.replace(/%H12/g, String(hours % 12))
		.replace(/%a/g, hours < 12 ? 'AM' : 'PM')
		.replace(/%H/g, String(hours).padStart(2, '0'))
		.replace(/%-H/g, String(hours))

		.replace(/%M/g, String(minutes).padStart(2, '0'))
		.replace(/%-M/g, String(minutes))

		.replace(/%S/g, String(seconds).padStart(2, '0'))
		.replace(/%-S/g, String(seconds));
};

// format a number
Number.prototype.format = function (decimalPlaces = 0) {
	return String(this.toFixed(decimalPlaces))
		.replace('.', ',')
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};
